import * as React from "react";

import { PageProps, graphql } from "gatsby";
import { brandsToExclude, categoriesToExclude } from "../queries/toExclude";
import useAuth, { GET_USER } from "../components/useAuth";

import { FetchAllBrands } from "../components/hooks/fetchAllBrands";
import { FetchAllCategories } from "../components/hooks/fetchAllCategories";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import ShopProductsContainer from "../components/Shop/shop-products-container";
import { fetchAllProducts } from "../components/hooks/queries";
import { navigate } from "gatsby";
import { useQuery } from "@apollo/client";
import { useState } from "react";

const Shop: React.FC<Shop> = ({ data, pageContext, location }: any) => {
  const {
    allWpSimpleProduct: { nodes: allSimpleNodes },
    allWpVariableProduct: { nodes: allVariableNodes },
    wpProductTag: {
      description: any,
      catDescriptionTop: { descriptionTop },
    },
  } = data;

  //*ovo je najpametnija stvar koju sam ikad uradila
  const allNodes = [...allSimpleNodes, ...allVariableNodes];

  // *SORT
  // initial array of all products
  const params = new URLSearchParams(location.search);
  const pageParam = params.get("page");

  const brands = FetchAllBrands();
  const categories = FetchAllCategories();

  const { data: userData } = useQuery(GET_USER);
  const { loggedIn, isDefaultCustomer } = useAuth();

  const isUnlicensedUser = userData?.viewer?.capabilities.includes(
    "unlicensed_customer"
  );

  const nodes = React.useMemo(() => {
    let allFilteredNodes = allNodes;

    if (isDefaultCustomer || isUnlicensedUser || !loggedIn) {
      allFilteredNodes = allNodes?.filter((item: any) => {
        return item.productTags?.nodes.every(
          (tag: any) =>
            tag.name !== "Unlicensed" && tag.name !== "Pharmaceuticals"
        );
      });
    }

    return allFilteredNodes;
  }, [isDefaultCustomer || isUnlicensedUser, allNodes, loggedIn]);

  const excludedBrandSlug = "medical-devices";
  const brandSlug =
    allNodes.length > 0 && allNodes[0].productTags.nodes.length > 0
      ? allNodes[0].productTags.nodes.find(
          (node: any) => node.slug !== excludedBrandSlug
        )?.slug || ""
      : "";

  const filteredBrands = React.useMemo(() => {
    if (isDefaultCustomer || isUnlicensedUser || !loggedIn) {
      return brands.filter((item: any) => {
        return !brandsToExclude.includes(item.name);
      });
    }

    return brands.filter((item: any) => {
      return (
        item.name !== "Unlicensed" &&
        item.name !== "Medical Devices" &&
        item.name !== "Pharmaceuticals"
      );
    });
  }, [isDefaultCustomer, isUnlicensedUser, brands]);

  const filteredProducts = React.useMemo(() => {
    let allFilteredProducts = allNodes;

    if (isUnlicensedUser || !loggedIn || isDefaultCustomer) {
      allFilteredProducts = allNodes.filter((item: any) => {
        return item.productTags?.nodes.every(
          (tag: any) =>
            tag.name !== "Unlicensed" && tag.name !== "Pharmaceuticals"
        );
      });
    }
    return allFilteredProducts;
  }, [isUnlicensedUser, isDefaultCustomer, allNodes, loggedIn]);

  const allProductsPerPage = 20;
  const [productsPerPage, setProductsPerPage] = useState(allProductsPerPage);
  const [currentPage, setCurrentPage] = useState(Number(pageParam) || 1);
  const [input, setInput] = useState(Number(pageParam) || 1);

  const [checkedFilters, setCheckedFilters] = useState<string[]>([]);
  const [checkedBrandFilters, setCheckedBrandFilters] = useState<string[]>([
    pageContext.id,
  ]);
  const [priceRange, setPriceRange] = useState<{
    min: string | undefined;
    max: string | undefined;
  }>({ min: undefined, max: undefined });

  const [filtered, setFiltered] = useState(
    nodes
      ? nodes.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        })
      : []
  );

  const [minPrice, setMinPrice] = useState<string | undefined>(undefined);
  const [maxPrice, setMaxPrice] = useState<string | undefined>(undefined);

  const numberOfPages = React.useMemo(() => {
    return Math.ceil(
      (filtered.length > 0 ? filtered.length : nodes.length) / productsPerPage
    );
  }, [filtered, nodes]);

  React.useEffect(() => {
    if ((pageParam && !Number(pageParam)) || Number(pageParam) == 1) {
      navigate(`/shop/brand/${pageContext.slug.toLowerCase()}`);
    }
    if (Number(pageParam) > numberOfPages) {
      setCurrentPage(numberOfPages);
      navigate(
        `/shop/brand/${pageContext.slug.toLowerCase()}?page=${numberOfPages}`
      );
      setInput(numberOfPages);
    }
    if (Number(pageParam) < 1) {
      setCurrentPage(1);
      navigate(`/shop/brand/${pageContext.slug.toLowerCase()}`);
      setInput(1);
    }
  }, [pageParam, numberOfPages]);

  const pageInputRef = React.useRef<HTMLInputElement | null>(null);

  const handlePageInputSubmit = React.useCallback(
    (event: any) => {
      if (event.key === "Enter" && input && input !== currentPage) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const parsedInput = Number(input);
        pageInputRef?.current?.blur();
        if (parsedInput <= 1) {
          setCurrentPage(1);
          setInput(1);
          navigate(`/shop/brand/${pageContext.slug.toLowerCase()}`);
        } else if (parsedInput > numberOfPages) {
          setCurrentPage(numberOfPages);
          setInput(numberOfPages);
          navigate(
            `/shop/brand/${pageContext.slug.toLowerCase()}?page=${numberOfPages}`
          );
        } else {
          setCurrentPage(parsedInput);
          navigate(
            `/shop/brand/${pageContext.slug.toLowerCase()}?page=${parsedInput}`
          );
        }
      }
    },
    [input, currentPage]
  );

  React.useEffect(() => {
    if (pageInputRef.current) {
      pageInputRef.current.addEventListener("keypress", handlePageInputSubmit);
    }

    return () => {
      if (pageInputRef.current) {
        pageInputRef.current.removeEventListener(
          "keypress",
          handlePageInputSubmit
        );
      }
    };
  }, [pageInputRef, handlePageInputSubmit]);

  React.useEffect(() => {
    const copyArray = [...filtered];
    copyArray.sort((a: any, b: any) => {
      return a.name.localeCompare(b.name);
    });
    setFiltered(copyArray);
  }, []);

  const handleFilters = () => {
    let newProducts = nodes;

    const minPriceExists = typeof priceRange.min !== "undefined";
    const maxPriceExists = typeof priceRange.max !== "undefined";

    if (minPriceExists && maxPriceExists) {
      newProducts = newProducts.filter(
        (item: any) =>
          Number(item.price.replace(/[^0-9.-]+/g, "")) >= Number(minPrice) &&
          Number(item.price.replace(/[^0-9.-]+/g, "")) <= Number(maxPrice)
      );
    } else if (minPriceExists) {
      newProducts = newProducts.filter(
        (item: any) =>
          Number(item.price.replace(/[^0-9.-]+/g, "")) >= Number(minPrice)
      );
    } else if (maxPriceExists) {
      newProducts = newProducts.filter(
        (item: any) =>
          Number(item.price.replace(/[^0-9.-]+/g, "")) <= Number(maxPrice)
      );
    }

    setFiltered(newProducts);
  };

  React.useEffect(() => {
    handleFilters();
  }, [checkedFilters, checkedBrandFilters, priceRange]);

  const [isVisible, setIsVisible] = useState(false);
  function ShowMoretext() {
    setIsVisible((current) => !current);
  }

  return (
    <Layout>
      <>
        <ShopProductsContainer
          // @ts-ignore
          title={pageContext.name}
          location={location}
          brand={pageContext.slug}
          categories={categories}
          products={filteredProducts}
          brands={filteredBrands}
          isBrandPage
          baseUrl={brandSlug}
          pageContext={pageContext}
          description={data?.wpProductTag?.catDescriptionTop?.descriptionTop}
          categoryDescription={data?.wpProductTag?.description}
          brandDescription={data?.wpProductTag?.description}
        />
      </>
    </Layout>
  );
};

type Shop = PageProps<ShopProps>;

type ShopProps = {
  data: {
    allWpSimpleProduct: {
      totalCount: number;
      nodes: Array<{
        name: string;
        id: string;
        slug: string;
        price: string;
        databaseId: number;
        image: { sourceUrl?: string; altText?: string };
        productTags: { tag: [{ name: string; id: string }] };
      }>;
      pageInfo: {
        currentPage: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
        itemCount: number;
        perPage: number;
        totalCount: number;
        pageCount: number;
      };
    };
    wpProductTag: {
      description: string;
      catDescriptionTop?: {
        descriptionTop?: string;
      };
    };
  };
};

export const query = graphql`
	query AllProductsShop($slug: String!) {
		allWpSimpleProduct(
			filter: { productTags: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
		) {
			totalCount
			nodes {
				name
				id
				slug
				databaseId
				price
				regularPrice
				salePrice
				onSale
				productTags {
					nodes {
						name
						slug
					}
				}
				productTags {
					tag: nodes {
						name
						id
						slug
						databaseId
					}
				}
				image {
					sourceUrl
					altText
				}
					promotions {
						onPromotion
						additionalTag
					}
				... on WpSimpleProduct {
					id
					name
					regularPrice
					salePrice
					onSale
				}
			}
			pageInfo {
				currentPage
				hasNextPage
				itemCount
				totalCount
				perPage
				hasPreviousPage
				pageCount
			}
		}
		allWpVariableProduct(
			filter: { productTags: { nodes: { elemMatch: { slug: { eq: $slug } } } } }
		) {
			totalCount
			nodes {
				name
				id
				slug
				databaseId
				price
				image {
					sourceUrl
					altText
				}
				productTags {
					nodes {
						name
						slug
					}
				}
				productTags {
					tag: nodes {
						name
						id
						slug
					}
				}
			}
			pageInfo {
				currentPage
				hasNextPage
				itemCount
				totalCount
				perPage
				hasPreviousPage
				pageCount
			}
		}
		wpProductTag(slug: { eq: $slug }) {
			id
			databaseId
			count
			name
			slug
			description
			catDescriptionTop {
				descriptionTop
			}
			seo {
				title
				metaDesc
			}
		}
	}
`;

type HeadProps = {
  wpProductTag: {
    seo?: {
      title?: string;
      metaDesc?: string;
    };
  };
};

export const Head: React.FC<HeadProps> = ({ data }: any) => {
  const wpProductTag: {
    seo: { title: any; metaDesc: any };
  } = data;
  return (
    <SEO
      title={data.wpProductTag.seo?.title}
      description={data.wpProductTag.seo?.metaDesc}
    />
  );
};

export default Shop;
